import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _431b60c4 = () => interopDefault(import('../pages/careerOverview.vue' /* webpackChunkName: "pages/careerOverview" */))
const _65851fae = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ed86a890 = () => interopDefault(import('../pages/howWeWork.vue' /* webpackChunkName: "pages/howWeWork" */))
const _1a8b4b84 = () => interopDefault(import('../pages/industryOverview.vue' /* webpackChunkName: "pages/industryOverview" */))
const _0ae70ba1 = () => interopDefault(import('../pages/insight.vue' /* webpackChunkName: "pages/insight" */))
const _9c3e0aa8 = () => interopDefault(import('../pages/instagramBioLinks.vue' /* webpackChunkName: "pages/instagramBioLinks" */))
const _6c3dd1d7 = () => interopDefault(import('../pages/storyOverview.vue' /* webpackChunkName: "pages/storyOverview" */))
const _06e139a4 = () => interopDefault(import('../pages/whatWeDo.vue' /* webpackChunkName: "pages/whatWeDo" */))
const _2306f6cf = () => interopDefault(import('../pages/whoWeAre.vue' /* webpackChunkName: "pages/whoWeAre" */))
const _2a517686 = () => interopDefault(import('../pages/careersADayAtSchubergPhilis.vue' /* webpackChunkName: "pages/careersADayAtSchubergPhilis" */))
const _6b779e29 = () => interopDefault(import('../pages/careerHiringProcess.vue' /* webpackChunkName: "pages/careerHiringProcess" */))
const _1afcc735 = () => interopDefault(import('../pages/careerWhatWeOffer.vue' /* webpackChunkName: "pages/careerWhatWeOffer" */))
const _3f965ab0 = () => interopDefault(import('../pages/howWeWorkBizDevOps.vue' /* webpackChunkName: "pages/howWeWorkBizDevOps" */))
const _79555065 = () => interopDefault(import('../pages/howWeWorkLab.vue' /* webpackChunkName: "pages/howWeWorkLab" */))
const _6c97f26c = () => interopDefault(import('../pages/howWeWorkOurApproach.vue' /* webpackChunkName: "pages/howWeWorkOurApproach" */))
const _9424ec9a = () => interopDefault(import('../pages/storyAll.vue' /* webpackChunkName: "pages/storyAll" */))
const _523a683c = () => interopDefault(import('../pages/whoWeAreMissionAndVision.vue' /* webpackChunkName: "pages/whoWeAreMissionAndVision" */))
const _069132e5 = () => interopDefault(import('../pages/howWeWorkOurBelief.vue' /* webpackChunkName: "pages/howWeWorkOurBelief" */))
const _4d808078 = () => interopDefault(import('../pages/whoWeAreOurStory.vue' /* webpackChunkName: "pages/whoWeAreOurStory" */))
const _689af5b5 = () => interopDefault(import('../pages/whoWeArePeopleAndCulture.vue' /* webpackChunkName: "pages/whoWeArePeopleAndCulture" */))
const _9f0e60fe = () => interopDefault(import('../pages/careerCategories.vue' /* webpackChunkName: "pages/careerCategories" */))
const _0d26b10c = () => interopDefault(import('../pages/storyCategories.vue' /* webpackChunkName: "pages/storyCategories" */))
const _0e71907a = () => interopDefault(import('../pages/whatWeDoCapabilities.vue' /* webpackChunkName: "pages/whatWeDoCapabilities" */))
const _eb43195c = () => interopDefault(import('../pages/whatWeDoFocusAreas.vue' /* webpackChunkName: "pages/whatWeDoFocusAreas" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _21e006fa = () => interopDefault(import('../pages/industries.vue' /* webpackChunkName: "pages/industries" */))
const _6304765c = () => interopDefault(import('../pages/insightDetail.vue' /* webpackChunkName: "pages/insightDetail" */))
const _50075adc = () => interopDefault(import('../pages/stories.vue' /* webpackChunkName: "pages/stories" */))
const _c424c97a = () => interopDefault(import('../pages/storyBundles.vue' /* webpackChunkName: "pages/storyBundles" */))
const _3daa535a = () => interopDefault(import('../pages/industriesCase.vue' /* webpackChunkName: "pages/industriesCase" */))
const _63d05183 = () => interopDefault(import('../pages/generalPages.vue' /* webpackChunkName: "pages/generalPages" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _1479741b,
    name: "index___en"
  }, {
    path: "/nl",
    component: _1479741b,
    name: "index___nl"
  }, {
    path: "/en/careers",
    component: _431b60c4,
    name: "careerOverview___en"
  }, {
    path: "/en/contact",
    component: _65851fae,
    name: "contact___en"
  }, {
    path: "/en/how-we-work",
    component: _ed86a890,
    name: "howWeWork___en"
  }, {
    path: "/en/industries",
    component: _1a8b4b84,
    name: "industryOverview___en"
  }, {
    path: "/en/insights",
    component: _0ae70ba1,
    name: "insight___en"
  }, {
    path: "/en/instagram-bio",
    component: _9c3e0aa8,
    name: "instagramBioLinks___en"
  }, {
    path: "/en/stories",
    component: _6c3dd1d7,
    name: "storyOverview___en"
  }, {
    path: "/en/what-we-do",
    component: _06e139a4,
    name: "whatWeDo___en"
  }, {
    path: "/en/who-we-are",
    component: _2306f6cf,
    name: "whoWeAre___en"
  }, {
    path: "/nl/carrieres",
    component: _431b60c4,
    name: "careerOverview___nl"
  }, {
    path: "/nl/contact",
    component: _65851fae,
    name: "contact___nl"
  }, {
    path: "/nl/hoe-wij-werken",
    component: _ed86a890,
    name: "howWeWork___nl"
  }, {
    path: "/nl/industrie",
    component: _1a8b4b84,
    name: "industryOverview___nl"
  }, {
    path: "/nl/instagram-bio",
    component: _9c3e0aa8,
    name: "instagramBioLinks___nl"
  }, {
    path: "/nl/inzichten",
    component: _0ae70ba1,
    name: "insight___nl"
  }, {
    path: "/nl/verhalen",
    component: _6c3dd1d7,
    name: "storyOverview___nl"
  }, {
    path: "/nl/wat-wij-doen",
    component: _06e139a4,
    name: "whatWeDo___nl"
  }, {
    path: "/nl/wie-wij-zijn",
    component: _2306f6cf,
    name: "whoWeAre___nl"
  }, {
    path: "/en/careers/a-day-at-schuberg-philis",
    component: _2a517686,
    name: "careersADayAtSchubergPhilis___en"
  }, {
    path: "/en/careers/hiring-process",
    component: _6b779e29,
    name: "careerHiringProcess___en"
  }, {
    path: "/en/careers/what-we-offer",
    component: _1afcc735,
    name: "careerWhatWeOffer___en"
  }, {
    path: "/en/how-we-work/bizdevops",
    component: _3f965ab0,
    name: "howWeWorkBizDevOps___en"
  }, {
    path: "/en/how-we-work/lab-271",
    component: _79555065,
    name: "howWeWorkLab___en"
  }, {
    path: "/en/how-we-work/our-approach",
    component: _6c97f26c,
    name: "howWeWorkOurApproach___en"
  }, {
    path: "/en/stories/all",
    component: _9424ec9a,
    name: "storyAll___en"
  }, {
    path: "/en/who-we-are/mission-and-vision",
    component: _523a683c,
    name: "whoWeAreMissionAndVision___en"
  }, {
    path: "/en/who-we-are/our-belief",
    component: _069132e5,
    name: "howWeWorkOurBelief___en"
  }, {
    path: "/en/who-we-are/our-story",
    component: _4d808078,
    name: "whoWeAreOurStory___en"
  }, {
    path: "/en/who-we-are/people-and-culture",
    component: _689af5b5,
    name: "whoWeArePeopleAndCulture___en"
  }, {
    path: "/nl/carrieres/een-dag-bij-schuberg-philis",
    component: _2a517686,
    name: "careersADayAtSchubergPhilis___nl"
  }, {
    path: "/nl/carrieres/sollicitatie-proces",
    component: _6b779e29,
    name: "careerHiringProcess___nl"
  }, {
    path: "/nl/carrieres/wat-wij-bieden",
    component: _1afcc735,
    name: "careerWhatWeOffer___nl"
  }, {
    path: "/nl/hoe-wij-werken/bizdevops",
    component: _3f965ab0,
    name: "howWeWorkBizDevOps___nl"
  }, {
    path: "/nl/hoe-wij-werken/lab-271",
    component: _79555065,
    name: "howWeWorkLab___nl"
  }, {
    path: "/nl/hoe-wij-werken/onze-aanpak",
    component: _6c97f26c,
    name: "howWeWorkOurApproach___nl"
  }, {
    path: "/nl/verhalen/alle",
    component: _9424ec9a,
    name: "storyAll___nl"
  }, {
    path: "/nl/wie-wij-zijn/mensen-en-cultuur",
    component: _689af5b5,
    name: "whoWeArePeopleAndCulture___nl"
  }, {
    path: "/nl/wie-wij-zijn/missie-en-visie",
    component: _523a683c,
    name: "whoWeAreMissionAndVision___nl"
  }, {
    path: "/nl/wie-wij-zijn/ons-verhaal",
    component: _4d808078,
    name: "whoWeAreOurStory___nl"
  }, {
    path: "/nl/wie-wij-zijn/onze-overtuiging",
    component: _069132e5,
    name: "howWeWorkOurBelief___nl"
  }, {
    path: "/en/careers/categories/:slug",
    component: _9f0e60fe,
    name: "careerCategories___en"
  }, {
    path: "/en/stories/categories/:slug",
    component: _0d26b10c,
    name: "storyCategories___en"
  }, {
    path: "/en/what-we-do/capabilities/:slug",
    component: _0e71907a,
    name: "whatWeDoCapabilities___en"
  }, {
    path: "/en/what-we-do/focus-areas/:slug",
    component: _eb43195c,
    name: "whatWeDoFocusAreas___en"
  }, {
    path: "/nl/carrieres/categorieen/:slug",
    component: _9f0e60fe,
    name: "careerCategories___nl"
  }, {
    path: "/nl/verhalen/categorien/:slug",
    component: _0d26b10c,
    name: "storyCategories___nl"
  }, {
    path: "/nl/wat-wij-doen/branches/:slug",
    component: _eb43195c,
    name: "whatWeDoFocusAreas___nl"
  }, {
    path: "/nl/wat-wij-doen/mogelijkheden/:slug",
    component: _0e71907a,
    name: "whatWeDoCapabilities___nl"
  }, {
    path: "/en/careers/:slug",
    component: _cd863b84,
    name: "careers___en"
  }, {
    path: "/en/industries/:slug",
    component: _21e006fa,
    name: "industries___en"
  }, {
    path: "/en/insights/:slug",
    component: _6304765c,
    name: "insightDetail___en"
  }, {
    path: "/en/stories/:slug",
    component: _50075adc,
    name: "stories___en"
  }, {
    path: "/en/story-bundles/:slug",
    component: _c424c97a,
    name: "storyBundles___en"
  }, {
    path: "/nl/carrieres/:slug",
    component: _cd863b84,
    name: "careers___nl"
  }, {
    path: "/nl/industrie/:slug",
    component: _21e006fa,
    name: "industries___nl"
  }, {
    path: "/nl/inzichten/:slug",
    component: _6304765c,
    name: "insightDetail___nl"
  }, {
    path: "/nl/verhalen/:slug",
    component: _50075adc,
    name: "stories___nl"
  }, {
    path: "/nl/verhalenbundels/:slug",
    component: _c424c97a,
    name: "storyBundles___nl"
  }, {
    path: "/en/industries/:industry/:slug",
    component: _3daa535a,
    name: "industriesCase___en"
  }, {
    path: "/nl/industrie/:industry/:slug",
    component: _3daa535a,
    name: "industriesCase___nl"
  }, {
    path: "/en/:slug",
    component: _63d05183,
    name: "generalPages___en"
  }, {
    path: "/nl/:slug",
    component: _63d05183,
    name: "generalPages___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
